export const ContactService ={
    createContact
}

function createContact(contactData){
    const contactObject={
        contact: { ...contactData }
    }

    const url=`${process.env.REACT_APP_API_URL}/api/contacts`;

    return fetch(url,{
        method:'POST',
        headers:{
            'Content-Type':'application/json',
            'Accept':'application/json'
        },
        body:JSON.stringify(contactObject)
    }).then(handleErrors);
}
function handleErrors (response) {
    return response.json().then(data => {
      if (!response.ok) {

        const error = (data && data.error) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  };
