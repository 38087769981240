import React from 'react';
import './Header.scss';
import { Link } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import menuJson from './menu.json';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';

class Header extends React.Component{
    constructor(props){
        super(props)
        this.state={
            menu:menuJson
        }
    }
    render(){
        return(
            <div className='header'>
                <div className='header__header-container'>
                    <div className='header__mobile-header'>
                        <Link to='/' className='header__menu-link'>
                            <div className='header__logo-box'>
                              <ImageNextGen
                                class="header__logo-prometeus"
                                imageUrl={this.props.imageUrl}
                                imageUrlWebp={this.props.imageUrlWebp}
                              />
                                <h4 className={'header__logo-name header__logo-name-bold '+ this.props.changeColorClass || ''}>Prometeus</h4>
                                <h4 className={'header__logo-name ' + this.props.changeColorClass || '' }>Tech</h4>
                            </div>
                        </Link>
                        <HamburgerMenu/>
                    </div>
                    <div className='header__menu'>
                        <ul className='header__menu-list'>
                        {
                            this.state.menu.map(menuItem=>(
                                <Link to={menuItem.url} className='header__menu-link'key={menuItem.id}>
                                    <li className={'header__menu-item '+ this.props.changeColorClass || ''}>
                                        {menuItem.title}
                                    </li>
                                </Link>
                            ))
                        }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;
