import React, { Component } from 'react';

class ImageNextGen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      srcImage: ''
    };

    this.setImageProperSrc();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.setImageProperSrc();
    }
  }

  setImageProperSrc = () => {
    window.Modernizr.on('webp', result => {
      if (result) {
        this.setState({ srcImage: this.props.imageUrlWebp });
      } else {
        this.setState({ srcImage: this.props.imageUrl });
      }
    });
  };

  render() {
    return (
      <img
        className={this.props.class}
        src={this.state.srcImage}
        alt={this.props.imageUrl}
      />
    );
  }
}

export default ImageNextGen;
