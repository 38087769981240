import React, { Component } from 'react';
import './HamburgerMenu.scss';
import { Link } from 'react-router-dom';
import menuJson from '../Header/menu.json';

class HamburgerMenu extends Component{
    constructor(props){
        super(props);

        this.state={
            menu:menuJson,
            activeMenu:false
        }
    }

    openMenu=()=>{
        this.setState({activeMenu:true})
        document.body.classList.add('has-active-menu');
    }
    closeMenu=()=>{
        this.setState({activeMenu:false})
        document.body.classList.remove('has-active-menu');
    }
    render(){
        return(
            <div className="hamburger">
            <span>
              <img
                onClick={this.openMenu}
                className="hamburger__icon"
                src='/images/menu.svg'
                alt="hamburgetMenuLogo"
              />
            </span>
            <ul
              className={
                'hamburger__links' + (this.state.activeMenu ? ' active' : '')
              }
            >
              <li className=" hamburger__link--menu">Meniu</li>
              {this.state.menu.map(listItem => (
               <div key={listItem.id} className='hamburger__list-box'>
                  <Link className='hamburger__link' to={listItem.url} onClick={ () => this.closeMenu() }><li className='hamburger__list-item'>{listItem.title}</li></Link>
                </div>
              ))}
            </ul>
            <span>
              <img
                onClick={this.closeMenu}
                className={
                  'hamburger__close-icon' + (this.state.activeMenu ? ' active' : '')
                }
                src="/images/close-icon.png"
                alt="close Icon"
              />
            </span>
          </div>
        )
    }
}

export default HamburgerMenu;
