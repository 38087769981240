import React from 'react';
import '../../resources/main.scss';
import './Work.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import projectsJson from './projects.json';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';
import MetaTags from 'react-meta-tags';

class Work extends React.Component{
    constructor(props){
        super(props)
        this.state={
            projects:projectsJson
        }
    }
    scrollToTop=()=>{
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <div className='work'>
            <MetaTags>
                    <title>Proiecte Prometeus Tech</title>
                    <meta
                        name="description"
                        content= "Proiectele Prometeus Tech reflectă principiile și etica profesională a echipei noastre. "
                    />
                    <meta
                        name="keywords"
                        content="platforma primarii, website primarii, institutii publice, print 3d, tehnologii 3d, 3d, printare, hardware, development, comunitatea ta, blocul tau"
                    />
                    <meta property="og:title" content=" Proiectele Prometeus Tech" />
                    <meta property="og:description" content=" Proiectele Prometeus Tech reflectă principiile și etica profesională a echipei noastre." />
                </MetaTags>
                <div className='standard-first-section'>
                <Header imageUrl='/images/logo-prometeus.png' imageUrlWebp='/images/logo-prometeus.webp'/>

                    <div className='standard-intro'>
                        <h1 className='standard-title'>
                            Proiecte
                        </h1>
                        <h2 className='standard-subtitle'>
                            Proiectele Prometeus Tech reflectă principiile și etica profesională a echipei noastre.
                        </h2>
                    </div>
                </div>
                <div className='work__wrapper'>
                    {this.state.projects.map(project=>(
                        <div key={project.id} className="work__box">
                          <ImageNextGen
                            class="work__work-page-img"
                            imageUrl={project.imageUrl}
                            imageUrlWebp={project.imageUrlWebp}
                          />
                          <div className='work__info'>
                            <h2 className='work__section-title'>
                              {project.title}
                            </h2>
                            <h4 className='work__section-description'>
                              {project.description}
                            </h4>
                            <Link onClick={this.scrollToTop} to={'/proiect/' + project.slug} className='homepage__link'><h5 className='work__see-more'>Citește mai mult</h5></Link>
                        </div>
                    </div>
                    ))
                    }
                    </div>
                    <div className='standard-invitation full-width'>
                        <h1 className='standard-invite'>
                                Să începem colaborarea!
                        </h1>

                        <Link to='/contact' className='homepage__link'>
                         <button className='standard-work-button'>
                            <h5 className='standard-button-writing'>HAI SĂ VORBIM!</h5>
                        </button>
                        </Link>
                    </div>
                    <Footer/>
            </div>
        )
    }
}

export default Work;
