import React from 'react';
import '../../resources/main.scss';
import './Footer.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import menuJson from '../Header/menu.json';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component{
    constructor(props){
        super()
        this.state={
            menu: menuJson
        }
    }
    scrollToTop=()=>{
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <div className='footer'>
                <div className='footer__menu-social'>
                    <div className='footer__menu'>
                    <ul className='footer__menu-list'>
                        {
                            this.state.menu.map(menuItem=>(
                              <li className='footer__menu-item' key={menuItem.id}>
                                <Link to={menuItem.url} className='footer__menu-link' onClick={this.scrollToTop}>
                                    {menuItem.title}
                                </Link>
                              </li>
                            ))
                        }
                        </ul>
                    </div>
                    <div className='footer__social-media'>
                        <a href='https://www.facebook.com/prometeusTech/' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF} className='footer__social-media-icon'/></a>
                        <a href='https://www.linkedin.com/company/prometeus-tech' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn} className='footer__social-media-icon'/></a>
                    </div>
                </div>
                <div className='footer__contact'>
                    <div className='footer__contact-box'>
                        <p className='footer__contact-info'>0747774796</p>
                        <p className='footer__contact-info'>contact@prometeus-tech.com</p>
                    </div>
                    <p className='footer__contact-info'>©2020 Prometeus Tech. All rights reserved.</p>
                    <Link to='/termeni-si-conditii' className='footer__menu-link' onClick={this.scrollToTop}>
                    <p className='footer__contact-info'>Termeni și condiții</p>
                    </Link>
                    <p className='footer__contact-info footer__conduit-code' onClick={() => window.open(
                    '/documents/code_of_conduit_prometeus_tech.pdf',
                    '_blank')}>Codul de conduită</p>
                </div>
            </div>
        )
    }
}

export default Footer;
