import React from 'react';
import '../../resources/main.scss';
import './TermsAndConditions.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

class TermsAndConditions extends React.Component{
    scrollToTop=()=>{
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <div className='standard terms'>
                <MetaTags>
                    <title>Termeni si conditii Prometeus Tech</title>
                        name="keywords"
                        content="platforma primarii, website primarii, institutii publice, print 3d, tehnologii 3d, 3d, printare, hardware, development, comunitatea ta"
                    />
                    <meta property="og:title" content="Termeni si conditii " />
                </MetaTags>
                <div className='standard-first-section'>
                  <Header imageUrl='/images/logo-prometeus.png' imageUrlWebp='/images/logo-prometeus.webp'/>

                    <div className='standard-intro services__intro terms__intro'>
                        <h1 className='standard-title terms__title'>
                            Termeni și condiții
                        </h1>
                    </div>
                </div>
                <div className='terms__wrapper'>
                    <h3 className='terms__subtitle'>ACCEPTAREA CONDIȚIILOR</h3>
                    <p className='terms__info'>Prin accesarea acestui site web si/sau a oricarei pagini a acestuia sunteti de acord cu aceste conditii de utilizare. Daca nu sunteti de acord cu acesti termeni si conditii de utilizare, nu accesati acest site.</p>
                    <h3 className='terms__subtitle'>DESCRIEREA SERVICIILOR</h3>
                    <p className='terms__info'>Siteul nostru va pune la dispozitie informatiile din acest site web in scop informativ general si nu garanteaza de exactitatea lor la un moment dat, desi se va incerca pe cat posibil ca la publicarea lor pe site toate informatiile sa fie exacte.</p>
                    <h3 className='terms__subtitle'>INTRODUCERE</h3>
                    <p className='terms__info'>Prezentele Conditii Generale definesc conditiile de utilizare a site-ului PROMETEUS TECH de catre potentialii vizitatori sau clienti. Accesând si navigând pe acest site, acceptati termenii de utilizare descrisi în continuare.</p>
                    <h3 className='terms__subtitle'>DREPTURILE ASUPRA CONȚINUTULUI SITE-ULUI</h3>
                    <p className='terms__info'>PROMETEUS TECH nu va transfera titlul de proprietate asupra aplicatiilor software.</p>
                    <p className='terms__info'>PROMETEUS TECH detine drepturi complete si depline asupra titlului de proprietate si prin aceasta toate drepturile de autor si cele brevetate. Nu aveti dreptul sa redistribuiti, vindeti, decompilati, dezasamblati aplicatia software într-o forma perceptibila de catre oameni.</p>
                    <p className='terms__info'>Toate informatiile, produsele sau aplicatiile continute în acest site sunt proprietatea PROMETEUS TECH, care îsi rezerva dreptul de a modifica continutul si/sau structura site-ului în orice moment si fara nici o informare prealabila.</p>
                    <p className='terms__info'>Întregul continut al site-ului PROMETEUS TECH este protejat prin legea drepturilor de autor, toate drepturile fiind rezervate. Toate drepturile referitoare la pagini, continutul si prezentarea site-ului sunt detinute de PROMETEUS TECH. Este interzisa copierea, modificarea, afisarea, distribuirea, transmiterea, publicarea, comercializarea, licentierea, crearea unor materiale derivate sau utilizarea continutului site-ului în orice scop fara confirmarea scrisa din partea PROMETEUS TECH.</p>
                    <p className='terms__info'>Accesul si utilizarea paginii PROMETEUS TECH sunt gratuite si au scopul de a veni in ajutorul utilizatorilor pentru a gasi informatiile necesare în cel mai usor si rapid mod posibil, conform cerintelor fiecaruia.</p>
                    <p className='terms__info'>Informatiile prezente pe PROMETEUS TECH sunt de interes general si sunt puse la dispozitia utilizatorilor în mod gratuit. Prin termenul „utilizator” al acestei pagini se întelege orice persoana fizica sau juridica care va accesa pagina. Puteti copia si tipari continutul paginii PROMETEUS TECH pentru folosinta dumneavoastra personala, fara intentii comerciale. În orice alte situatii, continutul PROMETEUS TECH nu poate fi reprodus, modificat sau exploatat fara consimtamântul explicit al reprezentantilor PROMETEUS TECH.</p>

                    <h3 className='terms__subtitle'>NEANGAJAREA RĂSPUNDERII</h3>
                    <p className='terms__info'>Continutul informatiilor se refera la descrierea într-un anumit grad de detaliere a activitatilor, produselor si serviciilor PROMETEUS TECH. PROMETEUS TECH nu va acorda nici o garantie referitoare la:</p>
                    <ul className='terms__list'>
                        <li className='terms__list-item'>evitarea utilizarii anevoioase sau întreruperii în utilizare a site-ului;</li>
                        <li className='terms__list-item'>neafectarea în sens negativ a altor sisteme prin utilizarea site-ului;</li>
                    </ul>
                    <p className='terms__info'>Astfel, PROMETEUS TECH nu poate fi responsabila pentru nici un fel de daune directe sau indirecte produse prin utilizarea site-ului sau.</p>
                    <p className='terms__info'>Toate informatiile prezentate pe site cu privire la produsele PROMETEUS TECH, preturi, informatii, campanii si promotii de marketing, aspecte tehnice, sunt prezentate cu titlu informativ. Nimic din continutul site-ului PROMETEUS TECH nu poate constitui o oferta ferma de a contracta si nu poate angaja raspunderea Amigio în lipsa unor acorduri ulterioare.</p>
                    <p className='terms__info'>GDPR este un set de reguli bazat pe principii complexe, care este deschis la interpretare si cotine, de asemenea, numeroase domenii în care statele membre sunt invitate sa intervina. În prezent exista foarte putine îndrumari cu privire la implementarea GDPR si este posibil ca Autoritatile de Supraveghere sa aiba puncte de vedere diferite fata de cele exprimate în acest sablon. În ciuda eforturilor noastre de a oferi cele mai bune servicii, nu putem anticipa cu exactitate modul în care Autoritatea de Supraveghere sau instantele de judecata vor interpreta Regulamentul.</p>
                    <p className='terms__info'>PROMETEUS TECH nu face nicio declaratie, promisiune sau garantie cu privire la exactitatea, exhaustivitatea sau caracterul adecvat al prezentului continut, al îndrumarilor si a consultantei, nu îsi asuma nicio obligatie de rezultat sau diligenta fata de nicio persoana, exclud în mod expres si neaga raspunderea pentru orice cost, pierdere sau daunele suferite ca urmare a utilizarii continutului.</p>
                    <h3 className='terms__subtitle'>OBIECTIVUL CONȚINUTULUI SITE-ULUI</h3>
                    <p className='terms__info'>Obiectivul continutului site-ului este de a transmite informatii actualizate si exacte.</p>
                    <p className='terms__info'>PROMETEUS TECH nu poate garanta ca prezentele pagini nu contin erori, si asigura ca va depune toate diligentele pentru realizarea unei informari corecte si remedierea eventualelor erori.</p>
                    <p className='terms__info'>Orice persoana care doreste sa-si procure unul din serviciile sau produsele prezentate în site este rugata sa contacteze PROMETEUS TECH prin unul din mijloacele afisate în pagina de Contact a site-ului, pentru a se informa atât asupra disponibilitatii serviciului sau produsului în cauza cât si asupra conditiilor contractuale, tarifelor si informatiilor tehnice sau de alta natura.</p>
                    <h3 className='terms__subtitle'>INFORMAȚII PERSONALE</h3>
                    <p className='terms__info'>Când prin intermediul prezentului site va sunt solicitate informatii, aceasta are ca scop identificarea dumneavoastra sau posibilitatea de a va contacta. Acest lucru este cu atât mai necesar în cazul folosirii aplicatiilor disponibile pe site.</p>
                    <p className='terms__info'>Natura informatiilor solicitate se refera în special la date personale (nume, numere de telefon), adresa de e-mail, modul în care sunt utilizate sau intentioneaza sa fie utilizate produsele si/sau serviciile PROMETEUS TECH, dar poate include si alte informatii aflate în strânsa legatura cu utilizarea serviciilor si/sau produselor solicitate.</p>
                    <p className='terms__info'>Cu scopul de a raspunde mai bine nevoilor si întrebarilor utilizatorilor site-ului, informatiile solicitate prin intermediul acestui site vor face obiectul unei stocari si prelucrari electronice.</p>
                    <h3 className='terms__subtitle'>INFORMAȚIILE OFERITE PRIN INTERMEDIUL SITE-ULUI</h3>
                    <p className='terms__info'>Orice persoana care viziteaza site-ul PROMETEUS TECH si care ofera date sau informatii cu caracter personal prin intermediul acestui site îsi manifesta acordul în mod expres si neechivoc pentru urmatoarele: prelucrarea acestor date si informatii personale de catre PROMETEUS TECH; transmiterii de materiale promotionale specifice operatiunilor de marketing direct; solutionarea de catre PROMETEUS TECH a cererilor, întrebarilor si reclamatiilor adresate (a se vedea pagina Contact din site); alte activitati întreprinse de PROMETEUS TECH si permise de lege, ce nu fac obiectul unei aprobari din partea destinatarului.</p>
                    <p className='terms__info'>PROMETEUS TECH va pastra confidentialitatea acestor informatii.</p>
                    <p className='terms__info'>Utilizarea în continuare a acestui site constituie acordul expres si neechivoc al dumneavoastra în conformitate cu prevederile legii 677/2001 si directivelor GDPR pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date.</p>
                    <p className='terms__info'>Pentru orice nelamurire în legatura cu exercitarea drepturilor dumneavoastra referitoare la utilizarea site-ului si la protectia utilizarii va rugam sa ne contactati prin intermediul sectiunii <Link to='/contact' className='terms__link' onClick={this.scrollToTop}>Contact</Link> din site.</p>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default TermsAndConditions;
