import React from 'react';
import '../../resources/main.scss';
import './WorkSection.scss';
import { Link } from 'react-router-dom';
import projectsJson from'../../containers/Work/projects.json';
import articlesJson from '../../containers/Updates/Updates.json';
import ImageNextGen from '../ImageNextGen/ImageNextGen';

class WorkSection extends React.Component{
    constructor(props){
        super(props)
        this.state={
            projects:projectsJson,
            articles:articlesJson
        }
    }
    scrollToTop=()=>{
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <div>
                <div className='work-section'>

                    <div className='work-section__left-section'>
                    <div className='work-section__box'>
                            <h1 className='work-section__title'>Proiecte</h1>
                            <h3 className='work-section__subtitle'>Website-uri performante, platforme care să faciliteze dialogul civic, produse 3D unice pe piață - proiectele noastre sunt diversificate și personalizate pe nevoile tale </h3>
                            <h5 className='homepage__view-more'><Link to='/proiecte' className='work-section__link'onClick={this.scrollToTop}>Vezi toate proiectele</Link></h5>
                        </div>
                        <div className='work-section__img-box'>
                        {this.state.projects.filter( project => (project.id % 2) === 0 ).slice(0, 1).map( project => (
                            <div key={project.id} className='work-section__img-info'>
                              <ImageNextGen
                                class="work-section__img"
                                imageUrl={project.imageUrl}
                                imageUrlWebp={project.imageUrlWebp}
                              />
                                <div className='work-section__details'>
                                <Link to={'/proiect/'+project.slug} className=' work-section__project-link homepage__link'onClick={this.scrollToTop}><h1 className='work-section__title-specific'id="project-title"> {project.title}</h1></Link>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className='work-section__right-section'>
                      {this.state.projects.filter( project => (project.id % 2) !== 0 ).slice(0, 2).map( project => (
                            <div key={project.id} className='work-section__img-info'>
                              <ImageNextGen
                                class="work-section__img"
                                imageUrl={project.imageUrl}
                                imageUrlWebp={project.imageUrlWebp}
                              />
                                <div className='work-section__details'>
                                <Link to={'/proiect/' + project.slug} className='work-section__project-link homepage__link'onClick={this.scrollToTop}><h2 className='work-section__title-specific'id="project-title"> {project.title}</h2></Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='work-section__updates'>
                        <div className='work-section__updates-box'>
                            <h1 className='work-section__title work-section__updates-description'>Noutăți</h1>
                            <h3 className='work-section__subtitle work-section__updates-description'>Privim constant spre viitor. Convingerea nostră este că inovațiile sunt calea spre progres și astfel, tindem spre o permanentă evoluție.</h3>
                            <h5 className='homepage__view-more'><Link to='/noutati' onClick={this.scrollToTop} className='work-section__link'>Vezi toate noutățile</Link></h5>
                        </div>
                        <div className='work-section__updates-container'>
                        {this.state.articles.slice(0, 2).map(article=>(
                            <div key={article.id} className='work-section__updates-individual'>
                              <div className='work-section__updates-presentation work-section__updates-presentation-1'>
                                    <button className='work-section__updates-button'>
                                        <h5 className='standard-button-writing'>NOU</h5>
                                    </button>
                                </div>
                                <div className='work-section__updates-info'>
                                    <h2 className='work-section__title-specific'>{article.title}</h2>
                                    <h5 className='work-section__updates-date'>{article.date}</h5>
                                    <h3 className='work-section__subtitle-specific work-section__updates-information'>{article.description}</h3>
                                    <h5 className='homepage__view-more'><Link to={'/articol/'+article.slug} onClick={this.scrollToTop} className='work-section__link' id='see-more'>Citește mai mult</Link></h5>
                                </div>
                            </div>
                        ))}

                        </div>
                </div>
            </div>
        )
    }
}


export default WorkSection;
