import React from 'react';
import './FourZeroFour.scss';
import  { Redirect } from 'react-router-dom'
import Header from '../Header/Header';

class FourZeroFour extends React.Component{
    state = {
        redirect: false,
        seconds:10
      }

      componentDidMount() {
        this.id = setTimeout(() => this.setState({ redirect: true }), 10000)
        this.myInterval = setInterval(() => {
            const  seconds= this.state.seconds;
            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                    clearInterval(this.myInterval) }
        }, 1000)
      }


    componentWillUnmount() {
        clearInterval(this.myInterval)
    }
    render(){
        return(
            <div className='standard fzf'>
                {this.state.redirect? <Redirect to="/" /> :
                    <div className='fzf__wrapper'>
                        <Header imageUrl='/images/logo-prometeus.png' imageUrlWebp='/images/logo-prometeus.webp' />
                        <h1 className='fzf__title'>
                            404 - Pagină inexistentă
                        </h1>
                        <h2 className='fzf__redirect'>
                        Vei fi redirecționat către pagina principală în {this.state.seconds} secunde.
                        </h2>
                        <img className='fzf__error-img' src='/images/error.jpg' alt='error'/>
                    </div> }
            </div>
        )
    }
}

export default FourZeroFour;
