import React from 'react';
import '../../resources/main.scss';
import './Contact.scss';
import Header from '../../components/Header/Header';
import NativeSelect from '@material-ui/core/NativeSelect';
import 'react-input-range/lib/css/index.css';
import Footer from '../../components/Footer/Footer';
import { ContactService} from './ContactService';
import ReCAPTCHA from 'react-google-recaptcha';
import { googleVerificationService } from '../../services/GoogleVerificationService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import MetaTags from 'react-meta-tags';

class Contact extends React.Component{
    constructor(props){
        super(props);

        this.state={
            name:"",
            email:"",
            service:"",
            message:""
        }
        this.handleChange=this.handleChange.bind(this);
        this.recaptchaRef = React.createRef();
    }

    componentWillMount() {
      window.grecaptcha.ready(function() {
        window.grecaptcha
          .execute(process.env.REACT_APP_PP_GOOGLE_VERIFICATION, {
            action: 'contact'
          })
          .then(function(token) {
            googleVerificationService.verifyToken(token).then(response => {
              if (response.data.score < 0.5) {
                window.location.assign('https://www.google.com');
              }
            });
          });
      });
    }

    componentDidMount() {
      this._notificationSystem = this.refs.notificationSystem;
    }

    handleChange(event){
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    handleSubmit = event => {
      event.preventDefault();
      this.setState({ disableSubmit: true });

      googleVerificationService
        .verifyToken(this.state.googleRecapchaValue, true)
        .then(response => {
          if (response.data.success) {
            ContactService
              .createContact(this.state)
              .then(response => {
                this._notificationSystem.addNotification({
                  message:
                    'Am primit cererea dumneavoastra. Va vom contacta in cel mai scurt timp.',
                  level: 'success',
                  position: 'tc'
                });

                this.setState({ disableSubmit: false });
                this.recaptchaRef.current.reset();
              })
              .catch(error => {
                this._notificationSystem.addNotification({
                  message:
                    'A aparut o problema la adaugare, faceti refresh la pagina si incercati din nou sau contactati direct primaria daca problema persista.',
                  level: 'error',
                  position: 'tc'
                });

                this.setState({ disableSubmit: false });
                this.recaptchaRef.current.reset();
              });
          } else {
            this._notificationSystem.addNotification({
              message: 'Va rugam completati ReCAPTCHA.',
              level: 'error',
              position: 'tc'
            });
            this.setState({ disableSubmit: false });
          }
        });
    }

    onChange = value => {
      this.setState({ googleRecapchaValue: value });
    };

    render(){
        return(
            <div className='contact'>
            <MetaTags>
                <title>Contact Prometeus Tech</title>
                <meta
                    name="keywords"
                    content="platforma primarii, website primarii, institutii publice, print 3d, tehnologii 3d, 3d, printare, hardware, development, comunitatea ta"
                />
                <meta property="og:title" content="Contact Prometeus Tech" />
            </MetaTags>
                <div className='standard-first-section contact__first-section'>
                  <Header imageUrl='/images/logo-prometeus.png' imageUrlWebp='/images/logo-prometeus.webp'/>
                    <div className='standard-intro'>
                        <h1 className='standard-title'>
                            Contact
                        </h1>
                        <h2 className='standard-subtitle'>
                            Contactează-ne și hai sa începem proiectarea visului tău!
                        </h2>
                    </div>
                </div>
                <div className='contact__container'>
                    <form className='contact__form' onSubmit={this.handleSubmit}>
                          <input
                            className='contact__input'
                            type="text"
                            name="name"
                            required
                            value={this.state.name}
                            onChange={this.handleChange}
                            placeholder="Nume"
                          />

                          <input
                            className='contact__input'
                            type="email"
                            name="email"
                            required
                            value={this.state.email}
                            onChange={this.handleChange}
                            placeholder="Email"/>

                        <NativeSelect
                            required
                            className='contact__input'
                            value={this.state.service}
                            name="service"
                            type="text"
                            onChange={this.handleChange}
                            placeholder="Serviciu"
                            inputProps={{
                                classes: {
                                    icon: 'white'
                                }
                            }}
                        >
                            <option value="">Selecteaza Serviciul</option>
                            <option value="Development">Development</option>
                            <option value="Printare 3D">Printare 3D</option>
                        </NativeSelect>

                        <textarea cols="30"
                          rows="5"
                          required
                          type='text'
                          className='contact__text-area'
                          placeholder='Mesajul tău'
                          name="message"
                          value={this.state.message}
                          onChange={this.handleChange}
                        />

                          <ReCAPTCHA
                            ref={this.recaptchaRef}
                            sitekey={process.env.REACT_APP_PP_GOOGLE_VERIFICATION_V2}
                            onChange={this.onChange}
                            theme="dark"
                          />
                          <br />

                          <button type='submit' className='contact__submit-button red-button'><h5 className='standard-button-writing'>TRIMITE</h5></button>
                    </form>
                </div>
                <Footer/>
                <NotificationSystem
                  ref="notificationSystem"
                  style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default Contact;
