import React from 'react';
import '../../resources/main.scss';
import './Updates.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import updatesJson from './Updates.json';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';
import MetaTags from 'react-meta-tags';

class Updates extends React.Component{
    constructor(props){
        super(props)
        this.state={
            boxes:{
                all:true,
                news:false,
                updates:false
            },
            updates:updatesJson
        }
    }
    togglePanel = type => {
      let updates = this.state.updates;

      if (type === 'all') {
        updates = updatesJson;
      } else {
        updates = updatesJson.filter(update => update[type] === true);
      }

      this.setState({ updates });
    }
    scrollToTop=()=>{
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <div>
                <MetaTags>
                    <title>Noutati Prometeus Tech</title>
                    <meta
                        name="keywords"
                        content="platforma primarii, website primarii, institutii publice, print 3d, tehnologii 3d, 3d, printare, hardware, development, comunitatea ta"
                    />
                    <meta property="og:title" content="Noutati si actualitati Prometeus Tech" />
                </MetaTags>
            <div className='updates'>
                <div className='standard-first-section updates__first-section'>
                <Header imageUrl='/images/logo-prometeus.png' imageUrlWebp='/images/logo-prometeus.webp'/>

                    <div className='standard-intro'>
                        <h1 className='standard-title'>
                            Noutăți
                        </h1>
                    </div>
                    <div className='updates__sections'>
                        <h3 className='standard-subtitle updates__sections-name' onClick={()=>this.togglePanel('all')} >
                          Toate
                        </h3>
                        <h3 className='standard-subtitle updates__sections-name' onClick={()=>this.togglePanel('news')}>
                          Noutăți
                        </h3>
                        <h3 className='standard-subtitle updates__sections-name' onClick={()=>this.togglePanel('updates')}>
                          Updates
                        </h3>
                    </div>
                </div>

                { this.state.updates.length ? (
                <div>
                  <div className='updates__container'>
                    <div className='updates__wrapper'>
                      { this.state.updates.map(article=>(
                        <div key={article.id} className='updates__individual'>
                            <div className='updates__title-wrapper'>
                              <ImageNextGen
                                class="updates__img"
                                imageUrl={article.imageUrl}
                                imageUrlWebp={article.imageUrlWebp}
                              />
                                { article.news ? (
                                  <button className='updates__button blue-button'>
                                      <h4 className='updates__button-writing'>NOU</h4>
                                  </button>
                                ) : (null)}
                                { article.updates ? (
                                  <button className='updates__button red-button'>
                                      <h4 className='updates__button-writing'>UPDATE</h4>
                                  </button>
                                ) : (null)}
                            </div>
                            <div className='updates__info'>
                                <h2 className='updates__section-title'> {article.title}
                                </h2>
                                <h5 className='updates__section-date'>{article.date}
                                </h5>
                                <h3 className='updates__section-description'>
                                    {article.brief}
                                </h3>
                                <Link className='updates__link'to={'/articol/' + article.slug}><h5 className='updates__see-more'>Citește mai mult</h5></Link>
                            </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>) : (
                  <div>
                    <h3>Nu avem rezultate pentru cautare.</h3>
                  </div>
                )}

            </div>
            <div className='standard-invitation'>
                        <h1 className='standard-invite'>
                                Să începem colaborarea!
                        </h1>
                        <Link onClick={this.scrollToTop} to='/contact' className='homepage__link'><button className='standard-work-button'>
                            <h5 className='standard-button-writing'>HAI SĂ VORBIM!</h5>
                            </button>
                        </Link>
                    </div>
                    <Footer/>
        </div>
        )
    }
}

export default Updates;
