import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  TrafficLayer
} from 'react-google-maps';

import { compose, withProps } from 'recompose';

const ContactMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBaooDKWX9EoMHKnOhfv6d7I2qy_1X8WJo&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `400px`, width: '100%' }} />,
    containerElement: <div className="google-location" />,
    mapElement: <div style={{ height: `400px`, width: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: 47.152555, lng: 27.607216 }}
  >
    <TrafficLayer autoUpdate />
    <Marker position={{ lat: 47.152555, lng: 27.607216 }} />
  </GoogleMap>
));

export default ContactMap;
