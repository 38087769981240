import React from 'react';
import '../../resources/main.scss';
import './Article.scss';
import '../../containers/Work/Work.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import articlesJson from  '../Updates/Updates.json';
import { Link } from 'react-router-dom';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';
import MetaTags from 'react-meta-tags';
import {
  PieChart, Pie, Legend, Tooltip
} from 'recharts';

class Article extends React.Component{
    constructor(props){
        super(props)
        this.state={
            article: articlesJson.find(article => article.slug === this.props.match.params.id)
        }
    }
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/3Leoa7f4/';

    render(){
        return(
            <div>
             <MetaTags>
                <title>{this.state.article.title}</title>
                <meta
                    name="description"
                    content={this.state.article.brief}
                />
                <meta
                    name="keywords"
                    content={this.state.article.keywords}
                />
                <meta
                  name="og:image"
                  content={"https://www.prometeus-tech.com/" + this.state.article.imageUrl}
                />
                <meta name="og:title" content={this.state.article.title}         />
                <meta property="og:url" content={"https://www.prometeus-tech.com/articol/" +this.state.article.slug} />
                <meta
                  name="og:description"
                  content={this.state.article.brief}
                />
            </MetaTags>
            <div className='article'>
                <div className='standard-first-section article__first-section'>
                  <Header imageUrl='/images/logo_prometeus-light.png' imageUrlWebp='/images/logo_prometeus-light.webp' changeColorClass='article__menu'/>
                <div>
                    <div className='standard-intro'>
                        <h1 className='standard-title article__change-color article__title'>
                            {this.state.article.title}
                        </h1>
                        <div className='article__date-box'>
                            <h4 className='article__date'>{this.state.article.date}</h4>
                            <button className='article__article-button'><h5 className='standard-button-writing article__button-writing'>articol</h5></button>
                        </div>
                    </div>
                    <ImageNextGen
                      class="article__article-img"
                      imageUrl={this.state.article.imageUrl}
                      imageUrlWebp={this.state.article.imageUrlWebp}
                    />
                    <div className='article__info'>
                    <h3 className='article__description'>
                      {this.state.article.brief}
                    </h3>
                    <h3 className='article__description'>
                      {this.state.article.description1}
                    </h3>
                    <h3 className='article__description'>
                    { this.state.article.description2}
                    </h3>
                </div>
                </div>
                </div>

                { this.state.article.imageUrl2 &&
                  <ImageNextGen
                    class="article__article-img article__article-img-second"
                    imageUrl={this.state.article.imageUrl2}
                    imageUrlWebp={this.state.article.imageUrl2Webp}
                  />
                }
                <div className='article__info'>
                    { this.state.article.subtitle &&
                      <h2 className='article__section-subtitle'>{this.state.article.subtitle}</h2>
                    }
                    {this.state.article.description3 &&
                      <h3 className='article__description'>{this.state.article.description3}</h3>
                    }
                    { this.state.article.description4 &&
                      <h3 className='article__description'> {this.state.article.description4}</h3>
                    }
                    { this.state.article.description5 &&
                      <h3 className='article__description'>{this.state.article.description5}</h3>
                    }
                </div>

                 {this.state.article.imageUrl3 &&
                   <ImageNextGen
                     class="article__article-img article__article-img-second"
                     imageUrl={this.state.article.imageUrl3}
                     imageUrlWebp={this.state.article.imageUrl3Webp}
                   />
                  }
                  { this.state.article.charts &&
                    <div className="article__charts-container">
                      <PieChart width={250} height={250} onMouseEnter={this.onPieEnter}>
                        <Pie dataKey="value" isAnimationActive={false} data={this.state.article.charts.hasWebsiteChart} cx={100} cy={100} outerRadius={60} />
                        <Tooltip />
                        <Legend verticalAlign="bottom" height={20}/>
                      </PieChart>
                      <PieChart width={250} height={250} onMouseEnter={this.onPieEnter}>
                        <Pie dataKey="value" isAnimationActive={false} data={this.state.article.charts.hasHttpAccessChart} cx={100} cy={100} outerRadius={60} />
                        <Tooltip />
                        <Legend verticalAlign="bottom" height={20}/>
                      </PieChart>
                      <PieChart width={250} height={250} onMouseEnter={this.onPieEnter}>
                        <Pie dataKey="value" isAnimationActive={false} data={this.state.article.charts.hasHasSSLChart} cx={100} cy={100} outerRadius={60} />
                        <Tooltip />
                        <Legend verticalAlign="bottom" height={20}/>
                      </PieChart>
                      <PieChart width={250} height={250} onMouseEnter={this.onPieEnter}>
                        <Pie dataKey="value" isAnimationActive={false} data={this.state.article.charts.hasHSTSChart} cx={100} cy={100} outerRadius={60} />
                        <Tooltip />
                        <Legend verticalAlign="bottom" height={20}/>
                      </PieChart>
                    </div>
                  }
                 <div className='article__info'>
                    { this.state.article.subtitle2 && <h2 className='article__section-subtitle'>{this.state.article.subtitle2}</h2> }
                    { this.state.article.description6 && <h3 className='article__description'>{this.state.article.description6}</h3> }
                    { this.state.article.description7 && <h3 className='article__description'>{this.state.article.description7}</h3> }
                    { this.state.article.description8 && <h3 className='article__description'>{this.state.article.description8}</h3> }
                    { this.state.article.description9 && <h3 className='article__description'>{this.state.article.description9}</h3> }
                    { this.state.article.description10 && <h3 className='article__section-subtitle'>{this.state.article.description10}</h3> }
                  </div>
            </div>
            <div className='standard-invitation'>
                <h1 className='standard-invite'>
                        Să începem colaborarea!
                </h1>
                <Link to='/contact' className='homepage__link'>
                    <button className='standard-work-button'>
                        <h5 className='standard-button-writing'>HAI SĂ VORBIM!</h5>
                    </button>
                </Link>
            </div>
            <Footer/>
            </div>
        )
    }
}


export default Article;
