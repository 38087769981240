import React from 'react';
import '../../resources/main.scss';
import './About.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import { Link } from 'react-router-dom';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';
import MetaTags from 'react-meta-tags';

class About extends React.Component{
    scrollToTop=()=>{
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <div>
          <MetaTags>
            <title>Despre Prometeus Tech</title>
            <meta
                name="description"
                content="Despre Prometeus Tech"
            />
            <meta
                name="keywords"
                content="platforma primarii, website primarii, institutii publice, print 3d, tehnologii 3d, 3d, printare, hardware, development, comunitatea ta"
            />
            <meta property="og:title" content="Despre Prometeus Tech" />
        </MetaTags>
            <div className='about'>
                <div className='standard-first-section'>
                    <Header imageUrl='/images/logo-prometeus.png' imageUrlWebp='/images/logo-prometeus.webp' />
                    <div className='standard-intro about__intro'>
                        <h1 className='standard-title'>
                            Despre noi
                        </h1>
                        <h2 className='standard-subtitle'>
                        Suntem aici pentru ca împreună să găsim o modalitate de a îmbina ideea ta cu tot ce are mai bun tehnologia de oferit.
                        </h2>
                    </div>
                </div>
                <div className='about__container'>
                <div className='about__mission'>
                        <div className='about__mission-wrapper'>
                            <h3 className='standard-section-title'>Misiunea noastră</h3>
                            <div className='standard-section-box'>
                                <h4 className='standard-section-description standard-section-description-first'>Digitalizarea în mediul business și cel din instituțiile publice este inevitabilă și necesară.</h4>
                                <h4 className='standard-section-description'>
                                Noi ne dorim ca acest proces să se desfășoare cât mai natural și profesional, cu captarea specificului fiecărei viziuni. Credem în unicitatea și importanța fiecărei idei, și considerăm că fiecare idee merită să aibă propria ei reflexie virtuală.</h4>
                            </div>
                        </div>
                    </div>
                    <ImageNextGen
                      class="about__about-img"
                      imageUrl='/images/about.jpg'
                      imageUrlWebp='/images/about.webp'
                    />

                    <div className='about__values'>
                        <div className='standard-title-box about__values-title-box'>
                            <h2 className='standard-section-title about__values-title'>Valorile noastre</h2>
                            <h4 className='standard-section-description'>Principiile noastre s-au născut din dorința de a oferi atât cele mai bune servicii, cât și de a oferi consultanță individualizată pentru proiectarea online a fiecărui vis în parte.</h4>
                        </div>
                        <div className='about__values-box'>
                            <div className='about__values-specific'>
                              <ImageNextGen
                                class="about__values-img"
                                imageUrl='/images/idea.png'
                                imageUrlWebp='/images/idea.webp'
                              />
                                <h5 className='about__values-description'>Atingerea potențialului maxim al unei idei - produsul pe care îl livrăm va fi concretizarea unei colaborări în care nicio latură a unei idei nu va rămâne neexplorată.</h5>
                            </div>
                            <div className='about__values-specific'>
                                <ImageNextGen
                                  class="about__values-img"
                                  imageUrl='/images/values-1.png'
                                  imageUrlWebp='/images/values-1.webp'
                                />
                                <h5 className='about__values-description'>Responsabilitate cetățenească - suntem conștienți și ne pasă de rolul nostru în cadrul comunității în care trăim și căutăm să creem un impact pozitiv prin produsele noastre asupra societății. </h5>
                            </div>
                            <div className='about__values-specific'>
                                <ImageNextGen
                                  class="about__values-img"
                                  imageUrl='/images/values.png'
                                  imageUrlWebp='/images/values.webp'
                                />
                                <h5 className='about__values-description'>Dedicație - fiecare proiect este relizat cu convingere și angajament personal. Credem în ideea cu care lucrăm și avem grijă ca acest lucru să se vadă.</h5>
                            </div>
                            <div className='about__values-specific'>
                                <ImageNextGen
                                  class="about__values-img"
                                  imageUrl='/images/medal.png'
                                  imageUrlWebp='/images/medal.webp'
                                />
                                <h5 className='about__values-description'>Calitate - ne asigurăm că asteptările clientilor sunt atinse de standardele pe care ni le impunem noi înșine. </h5>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='about__clients'>
                        <div className='standard-title-box'>
                            <h2 className='standard-section-title'>Clienții noștri</h2>
                            <h4 className='standard-section-description'>Fiecare colaborare ne-a adus un set de experiențe și abilități noi pe care abia așteptăm să le punem în aplicare în proiectele viitoare!</h4>
                        </div>
                        <div className='about__logos-container'>
                            <a className='about__logo-link' href='https://www.investimobiliare.ro/' target='_blank' rel="noopener noreferrer">
                              <ImageNextGen
                                class="about__logo"
                                imageUrl='/images/investLogo.png'
                                imageUrlWebp='/images/investLogo.webp'
                              />
                            </a>
                            <a className='about__logo-link' href='https://mobimoddesign.ro/' target='_blank' rel="noopener noreferrer">
                              <ImageNextGen
                                class="about__logo"
                                imageUrl='/images/mobi-mod-logo.png'
                                imageUrlWebp='/images/mobi-mod-logo.webp'
                              />
                            </a>
                            <a className='about__logo-link' href='https://www.unit-33.co.uk/' target='_blank' rel="noopener noreferrer">
                              <ImageNextGen
                                class="about__logo"
                                imageUrl='/images/unit-33-logo.png'
                                imageUrlWebp='/images/unit-33-logo.webp'
                              />
                            </a>
                            <a className='about__logo-link' href='https://www.primaria-vicovudesus.ro/' target='_blank' rel="noopener noreferrer">
                              <ImageNextGen
                                class="about__logo about__logo-city-council"
                                imageUrl='/images/stema-vicovu-de-sus.png'
                                imageUrlWebp='/images/stema-vicovu-de-sus.webp'
                              />
                            </a>
                            <a className='about__logo-link' href='https://www.primariavicovudejos.ro/' target='_blank' rel="noopener noreferrer">
                              <ImageNextGen
                                class="about__logo about__logo-city-council"
                                imageUrl='/images/stema-vicovu-de-jos.png'
                                imageUrlWebp='/images/stema-vicovu-de-jos.webp'
                              />
                            </a>
                            <a className='about__logo-link' href='https://www.primariasucevita.ro/' target='_blank' rel="noopener noreferrer">
                              <ImageNextGen
                                class="about__logo about__logo-city-council"
                                imageUrl='/images/stema-sucevita.png'
                                imageUrlWebp='/images/stema-sucevita.webp'
                              />
                            </a>
                            <a className='about__logo-link' href='https://www.primariafratautiivechi.ro/' target='_blank' rel="noopener noreferrer">
                              <ImageNextGen
                                class="about__logo about__logo-city-council"
                                imageUrl='/images/stema-fratautii-vechi.png'
                                imageUrlWebp='/images/stema-fratautii-vechi.webp'
                              />
                            </a>
                            <a className='about__logo-link' href='https://www.getexponential.com/' target='_blank' rel="noopener noreferrer">
                              <ImageNextGen
                                class="about__logo about__logo-city-council"
                                imageUrl='/images/exponential-logo.png'
                                imageUrlWebp='/images/exponential-logo.webp'
                              />
                            </a>
                        </div>
                    </div>
                </div>
               <GoogleMap/>
                <div className='standard-invitation'>
                        <h1 className='standard-invite'>
                                Să începem colaborarea!
                        </h1>
                        <Link to='/contact' className='homepage__link'>
                            <button className='standard-work-button' onClick={this.scrollToTop}>
                                <h5 className='standard-button-writing'>Hai să vorbim!</h5>
                        </button>
                        </Link>
                    </div>
                    <Footer/>
                </div>
        )
    }
}


export default About;
