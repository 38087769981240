import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Homepage from '../src/containers/Homepage/Homepage.jsx';
import About from '../src/containers/About/About';
import Services from '../src/containers/Services/Services.jsx';
import Work from '../src/containers/Work/Work';
import Updates from '../src/containers/Updates/Updates.jsx';
import Contact from '../src/containers/Contact/Contact';
import Article from '../src/containers/Article/Article';
import Project from '../src/containers/Project/Project';
import TermsAndConditions from '../src/containers/TermsAndConditions/TermsAndConditions';
import CookieConsent from 'react-cookie-consent';
import FourZeroFour from '../src/components/FourZeroFour/FourZeroFour';

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};


class App extends React.Component {
  render(){
    return (
      <div className="container">
        <Router component={ScrollToTop}>
          <Switch>
            <Route exact path="/" component={Homepage}/>
            <Route exact path='/despre-noi' component={About}/>
            <Route exact path='/servicii' component={Services}/>
            <Route exact path='/proiecte' component={Work}/>
            <Route exact path='/noutati' component={Updates}/>
            <Route exact path='/contact' component={Contact}/>
            <Route exact path='/articol/:id' component={Article}/>
            <Route exact path='/proiect/:id' component={Project}/>
            <Route exact path='/termeni-si-conditii' component={TermsAndConditions}/>
            <Route component={FourZeroFour}/>
            <CookieConsent
              location="bottom"
              flipButtons
              buttonText="Accept"
              style={{ background: 'linear-gradient(90deg, #de0000 0%, #211919 0%)', borderTop: '1px solid black' }}
              buttonStyle={{ backgroundColor: 'red', color: 'white',  fontSize: '15px', fontWeight: 'bold' }}
              expires={150}
            >
              Acest site folosește cookies. Navigând în continuare vă exprimați
              acordul asupra folosirii cookie-urilor.
            </CookieConsent>
          </Switch>
        </Router>
      </div>
    );
  }

}

export default App;
