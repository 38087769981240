import React from 'react';
import '../../resources/main.scss';
import './Services.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';
import MetaTags from 'react-meta-tags';

class Services extends React.Component{
  scrollToTop=()=>{
    window.scrollTo(0, 0)
}
    render(){
        return(
            <div className='services'>
              <MetaTags>
                    <title>Servicii Prometeus Tech</title>
                    <meta
                        name="description"
                        content="Serviciile oferite de Prometeus Tech include developmentul de website-uri, printare 3d si produse hardware"
                    />
                    <meta
                        name="keywords"
                        content="platforma primarii, website primarii, institutii publice, print 3d, tehnologii 3d, 3d, printare, hardware, development, comunitatea ta"
                    />
                    <meta property="og:description" content="Serviciile oferite de Prometeus Tech include developmentul de website-uri, printare 3d si produse hardware" />
                </MetaTags>
                <div className='standard-first-section'>
                  <Header imageUrl='/images/logo-prometeus.png' imageUrlWebp='/images/logo-prometeus.webp'/>

                    <div className='standard-intro services__intro'>
                        <h1 className='standard-title'>
                            Servicii
                        </h1>
                        <h2 className='standard-subtitle'>
                          Prometeus Tech oferă un spectru larg de servicii, toate pentru a transforma un concept într-o formă pe care o poți împărtăși.
                        </h2>
                    </div>
                </div>
                    <div className='services__box'>
                      <ImageNextGen
                        class="services__img"
                        imageUrl='/images/services-2.jpg'
                        imageUrlWebp='/images/services-2.webp'
                      />
                        <div className='services__info'>
                          <ImageNextGen
                            class="services__icon"
                            imageUrl='/images/development-light.png'
                            imageUrlWebp='/images/development-light.webp'
                          />
                            <h2 className='services__section-title'> Development
                            </h2>
                            <h4 className='services__description'>
                              ”Trimite-mi link-ul” - sunt cuvintele inevitabile care vor urma atunci când ideea ta va fi auzită. Pentru a avea un răspuns imediat la această cerință, noi te ajutăm cu următoarele servicii:
                            </h4>
                            <ul className='services__list'>
                                <li className='services__item-list'>Website profesional</li>
                                <li className='services__item-list'>Platformă rapidă și ușor de utilizat</li>
                                <li className='services__item-list'>Aplicații moderne cu design personalizat</li>
                            </ul>
                        </div>
                    </div>
                    <div className='services__box services__box-reverse'>
                      <ImageNextGen
                        class="services__img"
                        imageUrl='/images/services-3d-printing.jpg'
                        imageUrlWebp='/images/services-3d-printing.webp'
                      />
                        <div className='services__info'>
                          <ImageNextGen
                            class="services__icon"
                            imageUrl='/images/3d-print-light.png'
                            imageUrlWebp='/images/3d-print-light.webp'
                          />
                            <h2 className='services__section-title'> Printare 3D
                            </h2>
                            <h4 className='services__description'>
                            Cu cea mai nouă tehnologie din domeniul printării îți aducem produsele unice de care ai nevoie pentru afacerea sau hobby-ul tău. Avantajele print-ului 3D sunt:
                            </h4>
                            <ul className='services__list'>
                                <li className='services__item-list'>Unicitate și specialitate </li>
                                <li className='services__item-list'>Costuri reduse</li>
                                <li className='services__item-list'>Implicare directă în printarea produselor</li>
                            </ul>
                        </div>
                    </div>
                    <div className='services__box'>
                      <ImageNextGen
                        class="services__img"
                        imageUrl='/images/services-harware.jpg'
                        imageUrlWebp='/images/services-harware.webp'
                      />
                        <div className='services__info'>
                          <ImageNextGen
                            class="services__icon"
                            imageUrl='/images/hardware-light.png'
                            imageUrlWebp='/images/hardware-light.webp'
                          />
                            <h2 className='services__section-title'> Hardware (În curând)
                            </h2>
                            <h4 className='services__description'>
                              Produse hardware de cea mai înaltă calitate pentru a-ți duce afacerea la următorul nivel
                            </h4>
                            <ul className='services__list'>
                                <li className='services__item-list'>Imprimante 3D</li>
                                <li className='services__item-list'>Piese imprimante, filamente și mentenanță</li>
                                <li className='services__item-list'>Sisteme performante la cerere</li>
                            </ul>
                        </div>
                    </div>
                    <div className='standard-invitation'>
                        <h1 className='standard-invite'>
                                Să începem colaborarea!
                        </h1>
                        <Link to='/contact' className='homepage__link'onClick={this.scrollToTop}>
                            <button className='standard-work-button'>
                            <h5 className='standard-button-writing'>Hai să vorbim!</h5>
                            </button>
                        </Link>
                    </div>
                    <Footer/>
        </div>
        )
    }
}


export default Services;
