import React from 'react';
import '../../resources/main.scss';
import './Project.scss';
import '../Work/Work.scss';
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import projectsJson from '../Work/projects.json';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';
import MetaTags from 'react-meta-tags';

class Project extends React.Component{
    constructor(props){
        super(props);

        this.state={
            project: projectsJson.find(project => project.slug === this.props.match.params.id)
        }
    }
    componentDidUpdate(prevProps) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        this.setState( {project: projectsJson.find(project => project.slug === this.props.match.params.id) });
        this.scrollToTop();
      }
    }
    scrollToTop=()=>{
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <div className='project'>
                <MetaTags>
                    <title>{this.state.project.title}</title>
                    <meta
                        name="description"
                        content={this.state.project.brief}
                    />
                    <meta
                        name="keywords"
                        content="platforma primarii, website primarii, institutii publice, print 3d, tehnologii 3d, 3d, printare, hardware, development, comunitatea ta, blocul tau"
                    />
                    <meta
                      name="og:image"
                      content={"https://www.prometeus-tech.com/" + this.state.project.imageUrl}
                    />
                    <meta name="og:title" content={this.state.project.title}         />
                    <meta property="og:url" content={"https://www.prometeus-tech.com/articol/" +this.state.project.slug} />
                    <meta
                      name="og:description"
                      content={this.state.project.brief}
                    />
                </MetaTags>
              <Header imageUrl='/images/logo_prometeus-light.png' imageUrlWebp='/images/logo_prometeus-light.webp' changeColorClass="project__change-color"/>
                    <div>
                       <div className='standard-intro'>
                        <h1 className='standard-title project__change-color project__title'>
                            {this.state.project.title}
                        </h1>
                        <a className='standard-link' href={this.state.project.href}>
                          <h3 className='standard-link-info project__link-info'>
                            {this.state.project.url}
                          </h3>
                        </a>
                        <h2 className='standard-subtitle project__subtitle project__change-color'>
                          {this.state.project.brief}
                        </h2>
                    </div>
                    <div className='project__middle-section'>
                      <ImageNextGen
                        class="project__middle-section-img"
                        imageUrl={this.state.project.imageUrl}
                        imageUrlWebp={this.state.project.imageUrlWebp}
                      />
                        <div className='project__details-box'>
                            <h4 className='project__section-title'>
                            MAI MULTE DESPRE PROIECT
                            </h4>
                            <h3 className='project__section-description'>
                            {this.state.project.description1}
                            </h3>
                            <h3 className='project__section-description'>
                            {this.state.project.description2}
                            </h3>
                            <ImageNextGen
                            class="project__middle-section-img"
                            imageUrl={this.state.project.imageUrl2}
                            imageUrlWebp={this.state.project.imageUrlWebp2}
                            />
                            <h3 className='project__section-description'>
                            {this.state.project.description3}
                            </h3>
                            <h3 className='project__section-description'>
                            {this.state.project.description4}
                            </h3>
                            <h3 className='project__section-description'>
                            {this.state.project.description5}
                            </h3>
                            <ImageNextGen
                            class="project__middle-section-img"
                            imageUrl={this.state.project.imageUrl3}
                            imageUrlWebp={this.state.project.imageUrlWebp3}
                            />
                            <h3 className='project__section-description'>
                            {this.state.project.description6}
                            </h3>
                            <a href={this.state.project.href} target="_blank" rel="noopener noreferrer">
                            <button className='project__website-link-button'><h5 className='project__link-project'>VEZI PROIECTUL</h5></button>
                            </a>
                        </div>
                    </div>
                            </div>

                    <div className='project__middle-section project__lower-section'>
                        <h2 className='project__related-projects'>Alte Proiecte</h2>
                        <div className='work__wrapper-two project__more-projects'>
                        {
                            this.state.project.relatedProjects.map(relatedProject=>(
                                <div key={relatedProject.id} className='work__individual work__individual-second project__individual'>
                                  <ImageNextGen
                                    class="work__work-page-img project__work-img"
                                    imageUrl={relatedProject.imageUrl}
                                    imageUrlWebp={relatedProject.imageUrlWebp}
                                  />
                                    <div className='work__info  work__info-second'>
                                        <h2 className='work__section-title'> {relatedProject.title}
                                        </h2>
                                        <h4 className='work__section-description'>
                                          {relatedProject.brief}
                                        </h4>
                                        <Link to={'/proiect/'+ relatedProject.slug} className='homepage__link'><h5 className='work__see-more'>Citește mai mult</h5></Link>
                                    </div>
                                    </div>
                            ))
                        }
                        </div>
                    </div>
                    <div className='standard-invitation'>
                        <h1 className='standard-invite'>
                                Să începem colaborarea!
                        </h1>
                        <Link to='/contact' className='homepage__link'onClick={this.scrollToTop}>
                            <button className='standard-work-button'>
                                <h5 className='standard-button-writing'>HAI SĂ VORBIM!</h5>
                            </button>
                        </Link>
                    </div>
                    <Footer/>

            </div>
        )
    }
}

export default Project;
