import React from 'react';
import './Homepage.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import WorkSection from '../../components/WorkSection/WorkSection';
import Footer from '../../components/Footer/Footer';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';
import MetaTags from 'react-meta-tags';

class Homepage extends React.Component{
    scrollToTop=()=>{
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div className='homepage'>
            <MetaTags>
                <meta
                    name="description"
                    content="Prometeus Tech doreste sa va ofere servicii de calitate, aplicatii web, mobile si servicii printare 3d."
                />
                <meta
                    name="keywords"
                    content="prometeus tech, development, prometeus 3d, digitalizare, platforma primarii, website primarii, institutii publice, platforma institutii publice, modele 3d, viziune, flexibilitate, digitalizare in mediul bussiness, website profesional, platforma rapida, platforma usor de folosit, aplicatii moderne, design specializat, unicitate, costuri reduse, printare 3d, proiecte personalizate, software solutions, IT, IT services, React, React Native, Ruby, Ruby on Rails"
                />
                <meta property="og:title" content="Prometeus Tech - Viziunea ta este scânteia. Noi te ajutăm să aprinzi flacăra" />
            </MetaTags>
                <div className='homepage__first-section'>
                    <Header imageUrl='/images/logo-prometeus.png' imageUrlWebp='/images/logo-prometeus.webp' />
                    <div className='homepage__intro'>
                        <div className='homepage__introduction-box'>
                            <h1 className='homepage__description'>
                               Viziunea ta este scânteia.
                            </h1>
                            <h1 className='homepage__description homepage__description-second'>
                               Noi te ajutăm să aprinzi flacăra.
                            </h1>
                        </div>
                          <div className='homepage__video'>
                            <ReactPlayer url='/videos/Flame.mp4'
                                    className='homepage__gif'
                                    background='transparent'
                                    width='75%'
                                    height='230px'
                                    loop={true}
                                    playing={true} />
                          </div>
                    </div>
                </div>
                    <div className='homepage__service-section'>
                        <div className='homepage__services-box'>
                            <h1 className='homepage__services-title'>Servicii</h1>
                            <h3 className='homepage__services-subtitle'>Flexibilitatea și atenția acordată fiecărui client sunt de esența serviciilor noastre.</h3>
                           <Link onClick={this.scrollToTop} to='/servicii' className='homepage__link'> <h5 className='homepage__view-more'>Vezi mai multe despre serviciile noastre</h5></Link>
                        </div>

                        <div className='homepage__services-specific-wrapper'>
                            <div className='homepage__services-specific'>
                              <ImageNextGen
                                class="homepage__services-img"
                                imageUrl='/images/development-dark.png'
                                imageUrlWebp='/images/development-dark.webp'
                              />
                                <h3 className='homepage__services-specific-title'>
                                Development
                                </h3>
                                <h4 className='homepage__services-specific-info'>
                                  Ai o idee de afacere? Următorul pas este să îți transpui ideile și viziunea într-un website profesional și o aplicație care o să te ducă la următorul nivel, rapid și ușor de folosit. Noi te ajutăm să îți creezi brand-ul în mediul virtual, pentru ca visul tău să fie la un click distanță de realitate.
                                </h4>
                                <Link to='/servicii'onClick={this.scrollToTop} className='homepage__link'><h5 className='homepage__view-more homepage__services-view-more'>Vezi mai mult</h5></Link>
                            </div>
                            <div className='homepage__services-specific'>
                              <ImageNextGen
                                class="homepage__services-img"
                                imageUrl='/images/hardware-dark.png'
                                imageUrlWebp='/images/hardware-dark.webp'
                              />
                                <h3 className='homepage__services-specific-title'>
                                Platforma pentru instituțiile publice
                                </h3>
                                <h4 className='homepage__services-specific-info'>
                                Ne dorim să facem parte din modernizarea colaborării dintre cetățeni și instituțiile publice. Platforma pe care am dezvoltat-o pentru primării nu doar că respectă toate cerințele legale, dar este și ușor de utilizat - atât pentru funcționarii publici, cât și pentru cetățeni.
                                </h4>
                                <Link to='/servicii'onClick={this.scrollToTop} className='homepage__link'><h5 className='homepage__view-more homepage__services-view-more'>Vezi mai mult</h5></Link>
                            </div>
                            <div className='homepage__services-specific'>
                              <ImageNextGen
                                class="homepage__services-img"
                                imageUrl='/images/3d-print-dark.png'
                                imageUrlWebp='/images/3d-print-dark.webp'
                              />
                                <h3 className='homepage__services-specific-title'>
                                Tehnologii 3D
                                </h3>
                                <h4 className='homepage__services-specific-info'>
                                   Modelele printate 3D pe care le oferim sunt limitate doar de imaginația ta. Pornim de la ideea ta și o aducem în 3D cu ajutorul celor mai noi tehnologii și o colaborare personalizată pentru nevoile tale.
                                </h4>
                                <Link to='/servicii' onClick={this.scrollToTop} className='homepage__link'><h5 className='homepage__view-more homepage__services-view-more'>Vezi mai mult</h5></Link>
                            </div>
                        </div>
                    </div>
                    <WorkSection/>
                    <div className='standard-invitation'>
                        <h1 className='standard-invite'>
                                Să începem colaborarea!
                        </h1>
                       <Link to='/contact' className='homepage__link'onClick={this.scrollToTop}>
                            <button className='standard-work-button'>
                                <h5 className='standard-button-writing'>Hai să vorbim!</h5>
                            </button>
                        </Link>
                    </div>
                    <Footer/>
                </div>
        )
    }
}

export default Homepage;
